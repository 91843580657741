<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <div class="row">
            <div class="col-md-10 col-12">
              <b>Disposisi Masuk</b>
            </div>

            <div class="col-md-2 col-12">
              <select
                class="pull-right form-control"
                v-model="from"
                @change="getFrom"
              >
                <option value="belum_dispo">Belum Didisposisi</option>
                <option value="sudah_dispo">Sudah Didisposisi</option>
                <option value="pencarian_khusus">Pencarian Khusus</option>
              </select>
            </div>
            <div
              style="width: 800px; margin: 0 auto"
              v-if="showFilter == false"
            >
              <div class="container">
                <div class="row justify-content-md-center">
                  <div class="col-md-12">
                    <form method="post" @submit.prevent="changeDt">
                      <div class="form-body">
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 300px">
                                <input
                                  placeholder="Pencarian Nomor Kendali"
                                  type="text"
                                  class="form-control"
                                  v-model="nomor_kendali"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 300px">
                                <input
                                  placeholder="Pencarian Nomor Surat"
                                  type="text"
                                  class="form-control"
                                  v-model="nomor_surat"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 300px">
                                <input
                                  placeholder="Pencarian Perihal / Isi Ringkas"
                                  type="text"
                                  class="form-control"
                                  v-model="cari"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-actions">
                        <div class="row justify-content-md-center">
                          <div class="col-md-7 pl-5">
                            <button
                              type="submit"
                              class="btn btn-success"
                              title="Simpan"
                            >
                              Submit</button
                            >&nbsp;
                            <button
                              type="button"
                              @click="resetInput()"
                              class="btn btn-inverse"
                              title="Reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="[
                'col-md-12 p-5 collapse card-filter-gt ',
                { in: showFilter },
              ]"
              transition="collapsing"
            >
              <div class="container">
                <div class="row justify-content-md-center">
                  <div class="col-md-12">
                    <form method="post" @submit.prevent="changeDt">
                      <div class="form-body">
                        <div class="row justify-content-md-center">
                          <label
                            class="text-right mt-2 col-md-3 col-sm-12"
                            style="font-size: 15px; font-weight: 700"
                            >Nomor / Perihal Surat</label
                          >
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="cari"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <label
                            class="text-right mt-2 col-md-3 col-sm-12"
                            style="font-size: 15px; font-weight: 700"
                            >Periode Surat</label
                          >
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 160px">
                                <input
                                  type="date"
                                  class="form-control"
                                  data-date=""
                                  data-date-format="YYYY-MM-DD"
                                  v-model="start_date"
                                />
                              </div>
                              <label
                                class="
                                  control-label
                                  text-right
                                  mt-2
                                  col-md-auto
                                "
                                >s/d</label
                              >
                              <div class="form-group" style="width: 160px">
                                <input
                                  type="date"
                                  class="form-control"
                                  data-date=""
                                  data-date-format="YYYY-MM-DD"
                                  v-model="end_date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-actions">
                        <div class="row justify-content-md-center">
                          <div class="col-md-7 pl-5">
                            <button
                              type="submit"
                              class="btn btn-success"
                              title="Simpan"
                            >
                              Submit</button
                            >&nbsp;
                            <button
                              type="button"
                              @click="resetInput()"
                              class="btn btn-inverse"
                              title="Reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </h3>
        <hr />

        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :dashboard="dashboard"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :onDetailTL="doDetailTL"
              :onDetailTLI="doDetailTLI"
              :forbidDetail="forbidRead"
              :forbidDetailTL="forbidRead"
              :forbidDetailTLI="forbidUpdate"
              :forbidDelete="true"
              :overideSearch="search"
              @onSearch="onSearch"
            />
          </div>
        </div>
      </div>
      <!-- {{ this.$store.state.profile.user.structural_position_id }} -->
    </div>
    <!-- <h3>{{ this.$store.state.dispositionIn.items.items }}</h3> -->
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";
import axios from "axios";

export default {
  components: {
    RotateSquare5,
    Table,
  },
  data() {
    let last = localStorage.getItem("last_request");
    last = last != null ? last.split("?") : ",?,".split("?");

    let roles = this.$store.state.profile.user.datarole1;

    // let jabat = this.$store.state.profile.user.structural_position_id;
    // let parame = {};
    // if (jabat > 0) {
    //   parame = 'pimpinan';
    // } else {
    //   parame = 'all';
    // }
    let params = {};
    if (last[0].includes("disposition_in")) {
      params = JSON.parse(last[1]);
    }

    return {
      // from:"all",
      // from:parame,
      from: roles,
      search: params.search || "",
      showFilter: false,
      cari: "",
      subject: "all",
      is_leader: "",
      headers: {
        description: {
          label: "Isi Ringkas",
          sortable: true,
        },
        // agenda_number: {
        //   label: "Nomor Agenda",
        //   sortable: true
        // },
        no_opd: {
          label: "Nomor Kendali",
          sortable: true,
        },
        nomor_surat: {
          label: "Nomor Surat",
          sortable: true,
        },
        asal_surat: {
          label: "Asal Surat",
          sortable: false,
        },
        send_dispo: {
          label: "Waktu Dispo Diterima",
          sortable: true,
          type: "dateTime",
        },
        tanggal_selesai: {
          label: "Tanggal Penyelesaian",
          sortable: true,
        },
        // tindak: {
        //   label: "Nomor Agenda",
        //   sortable: true,
        // },
        statusnote: {
          label: "Status Tindaklanjut",
          sortable: true,
        },
        penerima: {
          label: "Tujuan Disposisi",
          sortable: true,
        },
        instruksi: {
          label: "Instruksi",
          sortable: true,
        },
        // tindaklanjut: {
        //   label: "Hasil Tindaklanjut",
        //   sortable: true,
        // },
        // catatan: {
        //   label: "Catatan",
        //   sortable: true
        // },
        // jenis_dispo: {
        //   label: "Jenis Disposisi",
        //   sortable: true
        // },
        // is_cancel: {
        //   label: "Status Aktif",
        //   sortable: false
        // },
        // is_read: {
        //   label: "Dibaca",
        //   sortable: false,
        //   is_read: true
        // },
      },
    };
  },
  computed: {
    items() {
      let data = [];
      if (this.$store.state.dispositionIn.items !== undefined) {
        if (this.$store.state.dispositionIn.items.items !== undefined) {
          if (
            !Array.isArray(this.$store.state.dispositionIn.items.items) &&
            this.$store.state.dispositionIn.items.items !== undefined
          ) {
            data = Object.keys(this.$store.state.dispositionIn.items.items).map(
              (i) => this.$store.state.dispositionIn.items.items[i]
            );
          } else {
            data = this.$store.state.dispositionIn.items.items;
          }
        }
      }
      let newData = [];
      for (let index = 0; index < data.length; index++) {
        let element = data[index];
        let jenis_dispo = "";
        let send_dispo = "";
        if (element.is_recipient == 1 && element.is_personal == 0) {
          jenis_dispo = "Struktural";
        } else if (element.is_recipient == 0 && element.is_personal == 1) {
          jenis_dispo = "Personal";
        } else if (element.is_recipient == 1 && element.is_personal == 1) {
          if (element.is_leader == 1) {
            jenis_dispo = "Struktural & Personal";
          } else {
            jenis_dispo = "Personal";
          }
        }

        if (element.send_recipient != "0") {
          send_dispo = element.send_recipient;
        } else if (element.send_personal) {
          send_dispo = element.send_personal;
        } else {
          send_dispo = "";
        }

        newData[index] = {
          id: element.id,
          aksi: element.aksi,
          agenda_number: element.agenda_number,
          no_opd: element.no_opd,
          isLaporan: element.isLaporan,
          flag_atasan: element.flag_atasan,
          nomor_surat: element.nomorsurat,
          description: element.description,
          subject: element.subject,
          sifat: element.sifat,
          is_cancel:
            element.is_cancel == 0
              ? element.is_false
                ? "Salah kirim"
                : "Aktif"
              : "Dibatalkan",
          is_read: element.is_read,
          is_read_recipient: element.is_read_recipient,
          is_read_personal: element.is_read_personal,
          isBold:
            element.is_read_personal == 0 && element.is_read_recipient == 0
              ? true
              : false,
          catatan: element.note,
          document_jenis: element.document_jenis,
          tanggal_diterima: element.tanggal_diterima,
          redaksi: element.redaksi,
          asal_surat: element.document_asrat,
          unit_name: element.unit_name,
          pengirim: element.name,
          profil: element.username,
          jenis_dispo: jenis_dispo,
          send_dispo: send_dispo,
          tanggal_selesai: element.tanggal_penyelesaian,
          statusnote: element.statusnote,
          statusnote1: element.statusnote1,
          penerima: element.penerima,
          instruksi: element.instruksi,
          tindaklanjut: element.tindaklanjut,
          hasil: element.hasil,
          kembali: element.kembali,
          tanggal: element.tanggal,
          tanggal1: element.tanggal1,
          tanggal2: element.tanggal2,
          notif: element.notif,
          ketlanjut: element.ketlanjut,
          btl: element.btl,
          surat_masuk: element.surat_masuk,
          asal_surat: element.asal_surat,
          responsedispo: element.responsedispo,
        };
      }
      return newData;
    },
    permissions() {
      if (this.$store.state.profile === undefined) {
        return {
          disposition_in: {},
          document_in: {},
          document_out: {},
        };
      } else if (this.$store.state.profile.permissions === undefined) {
        return {
          disposition_in: {},
          document_in: {},
          document_out: {},
        };
      }
      return this.$store.state.profile.permissions;
    },
    filters() {
      let filt = {};
      if (this.$store.state.dispositionIn.items !== undefined) {
        if (this.$store.state.dispositionIn.items.filters !== undefined) {
          filt = this.$store.state.dispositionIn.items.filters;
        }
      }
      return filt;
    },
    dashboard() {
      let dash = {};
      if (this.$store.state.dispositionIn.items !== undefined) {
        if (this.$store.state.dispositionIn.items.dashboard !== undefined) {
          dash = this.$store.state.dispositionIn.items.dashboard;
        }
      }
      return dash;
    },
    // items() {
    //   var data = [];
    //   if(this.$store.state.dispositionIn.items){
    //     for (let index = 0; index < this.$store.state.dispositionIn.items.items.length; index++) {
    //       let element = this.$store.state.dispositionIn.items.items[index];
    //       const jenis_dispo = '';
    //       const send_dispo = '';
    //       if(element.is_recipient == 1 && element.is_personal == 0){
    //         jenis_dispo = 'Struktural';
    //       } else if(element.is_recipient == 0 && element.is_personal == 1){
    //         jenis_dispo = 'Personal';
    //       } else if(element.is_recipient == 1 && element.is_personal == 1){
    //         jenis_dispo = 'Struktural & Personal';
    //       }

    //       if(element.send_recipient != ''){
    //         send_dispo = element.send_recipient;
    //       } else if(element.send_personal){
    //         send_dispo = element.send_personal;
    //       } else {
    //         send_dispo = '';
    //       }

    //       data[index] = {
    //         id:element.id,
    //         agenda_number:element.agenda_number,
    //         nomor_surat:element.nomorsurat,
    //         subject:element.subject,
    //         sifat:element.sifat,
    //         is_cancel : element.is_cancel == 0 ? "Aktif" : "Dibatalkan",
    //         is_read:element.is_read,
    //         is_read_recipient:element.is_read_recipient,
    //         is_read_personal:element.is_read_personal,
    //         catatan:element.note,
    //         document_jenis:element.document_jenis,
    //         tanggal_diterima:element.tanggal_diterima,
    //         redaksi:element.redaksi,
    //         asal_surat:element.document_asrat,
    //         unit_name:element.unit_name,
    //         pengirim:element.name,
    //         profil:element.username,
    //         jenis_dispo:jenis_dispo,
    //         send_dispo: send_dispo
    //       };
    //     }
    //   }
    //   return data;
    // },
    // filters() {
    //   return this.$store.state.dispositionIn.items ? this.$store.state.dispositionIn.items.filters: {};
    // },
    state() {
      return this.$store.state.dispositionIn;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return !this.$store.state.profile.permissions.disposition_in.update;
      }
      return false;
    },
    canSeeAll() {
      if (this.$store.state.profile.permissions.disposition_in_all) {
        return this.$store.state.profile.permissions.disposition_in_all.read;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return !this.$store.state.profile.permissions.disposition_in.read;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return !this.$store.state.profile.permissions.disposition_in.delete;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      search: this.search,
      items: [],
      from: this.from,
      jabatan: this.$store.state.profile.user.structural_position_id,
    };
    this.$store.commit("dispositionIn/STATE", state);
    this.get(state);
  },
  methods: {
    onSearch(val) {
      this.search = val;
    },
    handleShowFilter() {
      this.showFilter = !this.showFilter;
    },
    changeDt() {
      // this.$children[0].$refs.table.reload();
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        cari: this.cari,
        nomor_kendali: this.nomor_kendali,
        nomor_surat: this.nomor_surat,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("dispositionIn/STATE", state);
      this.get(state);
    },
    get(val) {
      this.$store.dispatch("dispositionIn/getDispositionIn", val);
    },
    resetInput() {
      // this.start_date = moment().startOf("year").format("YYYY-MM-DD");
      // this.end_date = moment().endOf("year").format("YYYY-MM-DD");
      this.cari = "";
      this.nomor_kendali = "";
      this.nomor_surat = "";
      this.nomor_agenda = "";
      this.periode_surat = "";
      this.perihal = "";
      this.nomor_surat = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        cari: this.cari,
        start_date: this.start_date,
        end_date: this.end_date,
        nomor_kendali: this.nomor_kendali,
        nomor_surat: this.nomor_surat,
        nomor_agenda: this.nomor_agenda,
        perihal: this.perihal,
      };
      this.$store.commit("dispositionIn/STATE", state);
      this.get(state);
      this.$children[0].$refs.table.reload();
    },
    filterData: function () {
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        cari: this.cari,
        nomor_kendali: this.nomor_kendali,
        nomor_surat: this.nomor_surat,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("dispositionIn/STATE", state);
      this.get(state);
    },
    doDetail(val) {
      this.updateSendStatus(val.id);
      this.$store.dispatch("dispositionIn/onDetail", val);
    },
    doDetailTL(val) {
      this.$store.dispatch("dispositionIn/onDetailTL", val);
    },
    doDetailTLI(val) {
      this.$store.dispatch("dispositionIn/onDetailTLI", val);
    },
    doAtasan(val) {
      const data = {
        id: val.id,
        flag_atasan: 1,
      };
      // const data = JSON.stringify(payload);
      this.$store.dispatch("dispositionIn/submitTandaiAtasanDepan", data);
    },
    doEdit(val) {
      this.$store.dispatch("dispositionIn/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("dispositionIn/submitDelete", val);
    },
    doSend(val) {
      this.$store.dispatch("dispositionIn/submitSend", val);
    },
    updateSendStatus(id) {
      // eslint-disable-next-line no-unused-vars
      axios.get(`/disposition_in/read/${id}`).then((response) => {});
    },
    getFrom() {
      const state = {
        search: this.search,
        from: this.from,
      };
      if (this.from == "pencarian_khusus") {
        this.showFilter = !this.showFilter;
      }
      this.$store.commit("dispositionIn/STATE", state);
      this.get(state);
    },
  },
};
</script>
